import { useEffect, useState } from "react";
import "./App.css";
import * as THREE from "three";
import { Reflector } from "three/examples/jsm/Addons.js";
import { Easing, Tween, update as updateTween } from "tween";
import * as CollectionMaster from "./CollectionDesc.json";
function App() {
  const scene = new THREE.Scene();
  const camera = new THREE.PerspectiveCamera(
    75,
    window.innerWidth / window.innerHeight,
    0.1,
    1000
  );
  const renderer = new THREE.WebGLRenderer({ antialias: true });
  const loadingManager = new THREE.LoadingManager();
  loadingManager.onLoad = () => {
    document.getElementById("loader").style.opacity = 0;
    document.getElementById("logo").style.transform = "scale(0.1)";
    document.getElementById("logo").style.opacity = 0;
    document.getElementById("logo").style.pointerEvents = "none";
  };
  const textureLoader = new THREE.TextureLoader(loadingManager);
  const parentNode = new THREE.Object3D();
  const allImagesData = [
    {
      title: "Unbound Truth",
      artist: "SpiritualScientist",
      collection: "Stray Thoughts",
      path: "/images/unbound-truth.webp",
      widthRatio: 2,
      heightRatio: 2.75,
      magnify: 1.2,
      collectionInfo: "ST",
      link: 'https://foundation.app/mint/eth/0xEab986c3f6FB5B0D1c2833716fDF5F8B8e13361b/1'
    },
    {
      title: "Behemoth's Birth",
      artist: "SpiritualScientist",
      collection: "Stray Thoughts",
      path: "/images/behemoth-birth.webp",
      widthRatio: 2,
      heightRatio: 2.75,
      magnify: 1.2,
      collectionInfo: "ST",
      link: 'https://foundation.app/mint/eth/0xEab986c3f6FB5B0D1c2833716fDF5F8B8e13361b/4'
    },
    {
      title: "Behemoth's Discipline",
      artist: "SpiritualScientist",
      collection: "Stray Thoughts",
      path: "/images/behemoth-discipline.webp",
      widthRatio: 2,
      heightRatio: 2.75,
      magnify: 1.2,
      collectionInfo: "ST",
      link: 'https://foundation.app/mint/eth/0xEab986c3f6FB5B0D1c2833716fDF5F8B8e13361b/5'
    },
    {
      title: "Behemoth's Palace",
      artist: "SpiritualScientist",
      collection: "Stray Thoughts",
      path: "/images/behemoth-palace.webp",
      widthRatio: 2,
      heightRatio: 2.75,
      magnify: 1.2,
      collectionInfo: "ST",
      link: 'https://foundation.app/mint/eth/0xEab986c3f6FB5B0D1c2833716fDF5F8B8e13361b/3'
    },
    {
      title: "Everything/Nothing",
      artist: "SpiritualScientist",
      collection: "Reality Check",
      path: "/images/everything-nothing.webp",
      widthRatio: 2.25,
      heightRatio: 4,
      magnify: 0.8,
      collectionInfo: "RC",
      link: 'https://foundation.app/mint/base/0xC9E04F75e766C85F73f6e3EDC445E4f0755dc3Ad/2'
    },
    {
      title: "Birth of an Ophanim",
      artist: "SpiritualScientist",
      collection: "Blueprints from God",
      path: "/images/birth-of-ophanim.webp",
      widthRatio: 2.25,
      heightRatio: 4,
      magnify: 0.8,
      collectionInfo: "BfG",
      link: 'https://opensea.io/assets/ethereum/0x495f947276749ce646f68ac8c248420045cb7b5e/86736703122780243083394649627257269305560688445110559906526774306843186429972'
    },
    {
      title: "Window to the Soul",
      artist: "SpiritualScientist",
      collection: "Reality Check",
      path: "/images/window-to-the-soul.webp",
      widthRatio: 2.25,
      heightRatio: 4,
      magnify: 0.8,
      collectionInfo: "RC",
      link: 'https://foundation.app/mint/base/0xC9E04F75e766C85F73f6e3EDC445E4f0755dc3Ad/1'
    },
    {
      title: "Unrestricted Culture",
      artist: "SpiritualScientist",
      collection: "Interactive Thoughts",
      path: "/images/unrestricted-culture.webp",
      widthRatio: 3,
      heightRatio: 3,
      magnify: 0.8,
      collectionInfo: "IT",
      link: 'https://objkt.com/tokens/KT1Sw9GRw5kPstFbrZBHhkEcxYduYLHUNYih/0'
    },
    {
      title: "Unstable Faith",
      artist: "SpiritualScientist",
      collection: "Reality Check",
      path: "/images/unstable-faith.webp",
      widthRatio: 3,
      heightRatio: 3,
      magnify: 0.8,
      link: 'https://opensea.io/assets/ethereum/0x7725638c8336692ee71ce0e638e18e10215e3edb/2'
    },
    {
      title: "Embrace of the Eternal Will",
      artist: "SpiritualScientist",
      collection: "Crypts of a Wandering Artist",
      path: "/images/embrace-of-eternal-will.webp",
      widthRatio: 3,
      heightRatio: 3,
      magnify: 0.8,
      collectionInfo: "CWA",
      link: 'https://foundation.app/mint/eth/0x82DC2Cdac86418B1067f0c464A82b47ee712B139/3'
    },
    {
      title: "Significance of Bonds",
      artist: "SpiritualScientist",
      collection: "Deeper Crypts of a Wandering Artist",
      path: "/images/s-of-bonds.webp",
      widthRatio: 3,
      heightRatio: 3,
      magnify: 0.8,
      collectionInfo: "DCWA",
      link: 'https://opensea.io/assets/ethereum/0x1d5cc759112c809697eee0e28f7534b39226628c/1'
    },
    {
      title: "Spatial Nexus",
      artist: "SpiritualScientist",
      collection: "Spatial Nexus",
      path: "/images/guardian-of-light.webp",
      widthRatio: 3,
      heightRatio: 3,
      magnify: 0.8,
      link: 'https://foundation.app/mint/eth/0x95E13BC8C906eB09Ff1c66Cb444afFc611F8a4f3'
    },
  ];
  const links = {
    I: "http://instagram.com/_u/spiritual_scientist_art/",
    X: "https://x.com/spiritualS_art",
    F: "https://foundation.app/@SpiritualScientist",
  };

  let currentArtwork = allImagesData[0];
  let storedZoomObjectArray = [];
  let actionObjects = {
    sideDrawerOpen: false,
    artInfoOpen: false,
    isRotating: false,
    isZooming: false,
    fullWidthArtwork: false,
    artistInfoOpen: false,
    currentArtInfoSection: 0,
  };

  const artInfoSectionsWIP = ["artworkImageDiv", "artworkDesc", "collectionDesc"];
  const artInfoSections = ["artworkImageDiv"];


  var xDown = null;
  var yDown = null;

  let artworkNames =
    "Unbound Truth · Behemoth's Birth · Behemoth's Discipline · Behemoth's Palace · Everything/Nothing · Birth of an Ophanim · Window to the Soul · Unrestricted Culture · Unstable Faith · Embrace of the Eternal Will · Significance of Bonds · Spatial Nexus · ";

  function getTouches(evt) {
    return evt.touches || evt.originalEvent.touches;
  }

  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];
    xDown = firstTouch.clientX;
    yDown = firstTouch.clientY;
  }

  function handleTouchMove(evt) {
    if (!xDown || !yDown) {
      return;
    }
    var xUp = evt.touches[0].clientX;
    var yUp = evt.touches[0].clientY;

    var xDiff = xDown - xUp;
    var yDiff = yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      /*most significant*/
      if (!actionObjects.isRotating) {
        rotateGallery(xDiff > 0 ? -1 : 1);
      }
      if (actionObjects.artInfoOpen) {
        let canChangeSection = xDiff > 0 ? actionObjects.currentArtInfoSection != 0 : actionObjects.currentArtInfoSection != artInfoSections.length - 1;
        if(canChangeSection) {
          changeArtInfoSection(xDiff > 0 ? -1 : 1);
        }
      }
    } else {
      if (yDiff > 0) {
        if (actionObjects.artistInfoOpen && !actionObjects.artInfoOpen) {
          toggleDrawers("artistInfoOpen");
        }
      } else {
        if (!actionObjects.artistInfoOpen && !actionObjects.artInfoOpen) {
          toggleDrawers("artistInfoOpen");
        }
      }
    }
    xDown = null;
    yDown = null;
  }

  useEffect(() => {
    initGalleryScene();

    document.getElementById("title").innerHTML = currentArtwork.title;
    document.getElementById("artist").innerHTML = currentArtwork.collection;

    window.addEventListener("resize", () => {
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.updateProjectionMatrix();
      renderer.setSize(window.innerWidth, window.innerHeight);
    });

    window.addEventListener("click", (e) => {
      const raycaster = new THREE.Raycaster();
      const mouseNDC = new THREE.Vector2(
        (e.clientX / window.innerWidth) * 2 - 1,
        -(e.clientY / window.innerHeight) * 2 + 1
      );
      raycaster.setFromCamera(mouseNDC, camera);

      let interactions = raycaster.intersectObject(scene, true);
      if (interactions?.length > 0) {
        if (
          interactions?.[0]?.object?.name.toLowerCase().includes("arrow") &&
          !actionObjects.isRotating
        ) {
          rotateGallery(interactions[0].object.name == "rightArrow" ? -1 : 1);
        }
        if (checkInteractionPair(interactions)) {
          document.getElementById("artworkImage").src = getTexturePath(
            interactions[
              interactions.findIndex((item) => item.object?.data?.path)
            ].object.data.path
          );
          if (!somethingIsHappening()) {
            toggleDrawers("artInfoOpen");
          }
        }
      }
    });

    window.addEventListener("mousemove", (e) => {
      const raycaster = new THREE.Raycaster();
      const mouseNDC = new THREE.Vector2(
        (e.clientX / window.innerWidth) * 2 - 1,
        -(e.clientY / window.innerHeight) * 2 + 1
      );
      raycaster.setFromCamera(mouseNDC, camera);

      let interactions = raycaster.intersectObject(scene, true);
      if (interactions?.length == 2) {
        if (checkInteractionPair(interactions)) {
          if (!actionObjects.isZooming) {
            storedZoomObjectArray = interactions;
            zoomInArtwork();
          }
        }
      } else {
        zoomInArtwork(true);
      }
    });

    document.addEventListener("touchstart", handleTouchStart, false);
    document.addEventListener("touchmove", handleTouchMove, false);
  }, []);

  function initGalleryScene() {
    renderer.setPixelRatio(window.devicePixelRatio);
    renderer.setSize(window.innerWidth, window.innerHeight);
    let documentToRender = document.getElementById("mainContainer");
    documentToRender.appendChild(renderer.domElement);
    if (window.innerWidth >= 570) {
      let leftArrowTexture = textureLoader.load("/images/left.png");
      let rightArrowTexture = textureLoader.load("/images/right.png");
      let leftArrow = new THREE.Mesh(
        new THREE.BoxGeometry(0.3, 0.3, 1),
        new THREE.MeshStandardMaterial({
          map: leftArrowTexture,
          transparent: true,
        })
      );
      leftArrow.position.set(-1.3, 0, -3);
      leftArrow.rotation.y = 0.4;
      leftArrow.name = "leftArrow";
      let rightArrow = new THREE.Mesh(
        new THREE.BoxGeometry(0.3, 0.3, 1),
        new THREE.MeshStandardMaterial({
          map: rightArrowTexture,
          transparent: true,
        })
      );
      rightArrow.position.set(1.3, 0, -3);
      rightArrow.rotation.y = -0.4;
      rightArrow.name = "rightArrow";
      scene.add(leftArrow);
      scene.add(rightArrow);
    }
    allImagesData.forEach((imageItem, index) => {
      let texturePath = getTexturePath(imageItem.path);
      let texture = textureLoader.load(texturePath);
      texture.colorSpace = THREE.SRGBColorSpace;
      let baseNode = new THREE.Object3D();
      let artwork = new THREE.Mesh(
        new THREE.BoxGeometry(
          imageItem.widthRatio * (imageItem.magnify || 1),
          imageItem.heightRatio * (imageItem.magnify || 1),
          0.1
        ),
        new THREE.MeshStandardMaterial({ map: texture })
      );

      artwork.name = "artwork";
      artwork.data = imageItem;
      artwork.leftArrow =
        allImagesData[
          index - 1 > allImagesData.length - 1
            ? 0
            : index - 1 < 0
            ? allImagesData.length - 1
            : index - 1
        ];
      artwork.rightArrow =
        allImagesData[
          index + 1 > allImagesData.length + 1
            ? 0
            : index + 1 < 0
            ? allImagesData.length + 1
            : index + 1
        ];
      let border = new THREE.Mesh(
        new THREE.BoxGeometry(
          imageItem.widthRatio * (imageItem.magnify || 1) + 0.2,
          imageItem.heightRatio * (imageItem.magnify || 1) + 0.2,
          0.09
        ),
        new THREE.MeshStandardMaterial({ color: 0x303030 })
      );
      border.name = "border";
      border.position.z = -5.5;
      artwork.position.z = -5.5;
      baseNode.add(border);
      baseNode.add(artwork);
      baseNode.rotation.y = index * ((2 * Math.PI) / allImagesData.length);
      parentNode.add(baseNode);
    });

    scene.add(parentNode);

    let spotlight = new THREE.SpotLight(0xffffff, 100.0, 10.0, 0.65, 0.5);
    spotlight.position.set(0, 5, 0);
    spotlight.target.position.set(0, 0.5, -5);
    scene.add(spotlight);
    scene.add(spotlight.target);

    const mirror = new Reflector(new THREE.CircleGeometry(10), {
      color: 0x404040,
      textureHeight: window.innerHeight,
      textureWidth: window.innerWidth,
    });
    mirror.position.y = -1.75;
    mirror.rotateX(-Math.PI / 2);
    scene.add(mirror);
    renderer.setAnimationLoop(animate);
  }

  function rotateGallery(direction) {
    if (!somethingIsHappening()) {
      let index = allImagesData.findIndex(
        (imageItem) => imageItem.title == currentArtwork.title
      );
      let nextIndex =
        index + direction > allImagesData.length - 1
          ? 0
          : index + direction < 0
          ? allImagesData.length - 1
          : index + direction;
      currentArtwork = allImagesData[nextIndex];
      const finalPosition = -(
        direction *
        ((2 * Math.PI) / allImagesData.length)
      );
      new Tween(parentNode.rotation)
        .to({ y: parentNode.rotation.y + finalPosition })
        .easing(Easing.Quadratic.InOut)
        .start()
        .onStart(() => {
          document.getElementById("title").style.opacity = 0;
          document.getElementById("artist").style.opacity = 0;
          changeActionObjects("isRotating", true);
        })
        .onComplete(() => {
          document.getElementById("title").innerHTML =
            allImagesData[nextIndex].title;
          document.getElementById("artist").innerHTML =
            allImagesData[nextIndex].collection;
          document.getElementById("title").style.opacity = 1;
          document.getElementById("artist").style.opacity = 1;
          changeActionObjects("isRotating", false);
        });
    }
  }

  function zoomInArtwork(isReset = false) {
    if (storedZoomObjectArray.length) {
      if (!isReset) {
        storedZoomObjectArray.forEach((objectItem) => {
          new Tween(objectItem.object.position)
            .to({ z: -4.5 })
            .easing(Easing.Quadratic.InOut)
            .start()
            .onStart(() => {
              changeActionObjects("isZooming", true);
            })
            .onComplete(() => {
              changeActionObjects("isZooming", false);
            });
          if (objectItem.object.name == "border") {
            var col = new THREE.Color(0xffffff);
            new Tween(objectItem.object.material.color)
              .to({ r: col.r, g: col.g, b: col.b })
              .easing(Easing.Quadratic.InOut)
              .start()
              .onStart(() => {
                changeActionObjects("isZooming", false);
              });
          }
        });
      } else {
        storedZoomObjectArray.forEach((objectItem) => {
          new Tween(objectItem.object.position)
            .to({ z: -5.5 })
            .easing(Easing.Quadratic.InOut)
            .start();
          if (objectItem.object.name == "border") {
            var col = new THREE.Color(0x303030);
            new Tween(objectItem.object.material.color)
              .to({ r: col.r, g: col.g, b: col.b })
              .easing(Easing.Quadratic.InOut)
              .start()
              .onStart(() => {
                changeActionObjects("isZooming", true);
              })
              .onComplete(() => {
                changeActionObjects("isZooming", false);
              });
          }
        });
        storedZoomObjectArray = [];
      }
    }
  }

  function checkInteractionPair(interactions) {
    let interactionsMap = interactions
      .map((item) => item.object.name)
      .toString();
    let isCurrentArtwork =
      interactions.findIndex(
        (item) => item.object?.data?.title == currentArtwork.title
      ) != -1;
    return (
      (interactionsMap == "artwork,border" ||
        interactionsMap == "border,artwork") &&
      isCurrentArtwork
    );
  }

  function animate() {
    updateTween();
    renderer.render(scene, camera);
  }

  function openDrawer() {
    toggleDrawers(actionObjects.artInfoOpen ? "artInfoOpen" : "artistInfoOpen");
  }

  function toggleDrawers(objectName) {
    let transformType =
      objectName == "artistInfoOpen" ? "translateY(" : "translateX(";
    let transformDirection = objectName == "artistInfoOpen" ? "vh" : "vw";
    document.getElementById(objectName).style.transform = actionObjects[
      objectName
    ]
      ? `${transformType}-100${transformDirection})`
      : `${transformType}0${transformDirection})`;
    document.getElementById("closeButton").style.transform = !actionObjects[
      objectName
    ]
      ? "scale(1)"
      : "scale(0)";
    document.getElementById("closeButton").style.opacity = !actionObjects[
      objectName
    ]
      ? 1
      : 0;
    if (objectName == "artInfoOpen") {
      checkArtInfoButtons();
      document.getElementById("artworkDescInner").innerHTML =
        CollectionMaster[currentArtwork.collectionInfo];
      document.getElementById("collectionDescInner").innerHTML =
        CollectionMaster[currentArtwork.collectionInfo];
      if (actionObjects[objectName]) {
        actionObjects.currentArtInfoSection = 0;
        document.getElementById("zoomButton").style.opacity = 0;
        document.getElementById("zoomButton").style.transform = "scale(0)";

        document.getElementById("viewButton").style.opacity = 0;
        document.getElementById("viewButton").style.transform = "scale(0)";

        artInfoSections.forEach((item, index) => {
          document.getElementById(item).style.transform = "scale(1)";
          document.getElementById(item).style.opacity = 1;
          if (index != 0) {
            document.getElementById(item).style.transform = "translateX(-100%)";
          }
        });
      } else {
        document.getElementById("zoomButton").style.opacity = 1;
        document.getElementById("zoomButton").style.transform = "scale(1)";

        document.getElementById("viewButton").style.opacity = 1;
        document.getElementById("viewButton").style.transform = "scale(1)";
      }
    }
    changeActionObjects(objectName, !actionObjects[objectName]);
  }

  function getTexturePath(path) {
    let pathSplit = path.split("/");
    let endSplit = pathSplit[pathSplit.length - 1].split(".");
    pathSplit[1] = "textures";
    pathSplit[pathSplit.length - 1] =
      endSplit[0] + "-texture." + endSplit[endSplit.length - 1];
    return pathSplit.toString().replaceAll(",", "/");
  }

  function changeActionObjects(objectName, value) {
    actionObjects[objectName] = value;
  }

  function somethingIsHappening() {
    return (
      Object.keys(actionObjects).filter(
        (item) => actionObjects[item] && item != "fullWidthArtwork"
      ).length > 0
    );
  }

  function changeArtInfoSection(direction) {
    let sectionToHide = document.getElementById(
      artInfoSections[actionObjects.currentArtInfoSection]
    );
    actionObjects.currentArtInfoSection =
      actionObjects.currentArtInfoSection + direction;
    let sectionToShow = document.getElementById(
      artInfoSections[actionObjects.currentArtInfoSection]
    );

    checkArtInfoButtons();

    document.getElementById("zoomButton").style.transform =
      actionObjects.currentArtInfoSection != 0 ? "scale(0)" : "scale(1)";
    document.getElementById("zoomButton").style.opacity =
      actionObjects.currentArtInfoSection != 0 ? 0 : 1;

    sectionToHide.style.transform =
      direction == -1 ? "translateX(-100%)" : "scale(1)";
    sectionToShow.style.transform = "translateX(0%)";
    sectionToShow.style.opacity = 1;
  }

  function toggleArtworkSize() {
    document.getElementById("artworkImage").style.objectFit =
      !actionObjects.fullWidthArtwork ? "cover" : "contain";
    document.getElementById("zoomIcon").src = !actionObjects.fullWidthArtwork
      ? "images/minimize.png"
      : "images/maximize.png";
    changeActionObjects("fullWidthArtwork", !actionObjects.fullWidthArtwork);
  }

  function checkArtInfoButtons() {
    document.getElementById("leftInfoButton").style.transform = "scale(1)";
    document.getElementById("leftInfoButton").style.opacity = 1;

    document.getElementById("rightInfoButton").style.transform = "scale(1)";
    document.getElementById("rightInfoButton").style.opacity = 1;

    if (actionObjects.currentArtInfoSection == 0) {
      document.getElementById("rightInfoButton").style.transform = "scale(0)";
      document.getElementById("rightInfoButton").style.opacity = 0;
    }

    if (
      actionObjects.currentArtInfoSection ==
      artInfoSections.length - 1
    ) {
      document.getElementById("leftInfoButton").style.transform = "scale(0)";
      document.getElementById("leftInfoButton").style.opacity = 0;
    } 
  }

  function redirectToLink(path) {
    window.open(path != 'art' ? links[path] : currentArtwork.link, "_blank");
  }

  return (
    <div id="rootContainer">
      <div id="logo">
        <img
          className="loader"
          width="100"
          height="100"
          src="images/logo.jpg"
        ></img>
      </div>
      <div id="artInfoOpen">
        <div className="h-100 w-100 d-flex p-ab v-center z-9999">
          <div id="leftInfoButton" className="p-ab padding-10">
            <img
              className="action-button-bg padding-10 b-radius-max drop-shadow"
              src="images/left.png"
              width="20"
              height="20"
              onClick={() => changeArtInfoSection(1)}
            ></img>
          </div>
          <div id="rightInfoButton" className="p-ab right-0 padding-10">
            <img
              className="action-button-bg padding-10 b-radius-max drop-shadow"
              src="images/right.png"
              width="20"
              height="20"
              onClick={() => changeArtInfoSection(-1)}
            ></img>
          </div>
        </div>
        <div id="artworkImageDiv">
          <img
            id="artworkImage"
            onLoad={() => console.log("image loaded")}
          ></img>
        </div>
        <div id="artworkDesc">
          <div id="artworkDescInner">

          </div>
          <div id="collectionDescInner">
            
          </div>
        </div>
        <div id="collectionDesc">
          <div id="collectionDescInner">
            
          </div>
        </div>
      </div>
      <div id="sideDrawerOpen"></div>
      <div id="closeButton">
        <img
          id="closeIcon"
          src="images/close.png"
          onClick={() => openDrawer()}
        ></img>
      </div>
      <div id="viewButton" className="action-button-bg" onClick={() => redirectToLink('art')}>
        <img id="viewIcon" src="images/share.png"></img>View Full Resolution 
      </div>
      <div id="zoomButton" onClick={() => toggleArtworkSize()}>
        <img id="zoomIcon" src="images/maximize.png"></img> 
      </div>
      <div id="loader">
        <div id="artworkNamesContainer">
          <div id="artworkNames">
            <span>{artworkNames}</span>
          </div>
          <div id="artworkNamesReverse">
            <span>{artworkNames}</span>
          </div>
        </div>
        <div id="artworkNamesContainerReverse">
          <div id="artworkNamesLower">
            <span>{artworkNames}</span>
          </div>
          <div id="artworkNamesReverseLower">
            <span>{artworkNames}</span>
          </div>
        </div>
      </div>
      <div id="footerNav">
        <img
          className="menu"
          src="images/header-logo.png"
          onClick={() => toggleDrawers("artistInfoOpen")}
        ></img>
      </div>
      <div id="mainContainer"></div>
      <div id="artworkTextHeader">
        <h1 id="title"></h1>
      </div>
      <div id="artworkTextFooter">
        <h2 id="artist"></h2>
      </div>
      <div id="artistInfoOpen">
        <div className="artistImage">
          <div className="neo-box-bright d-flex v-center h-center">
            <img
              src="images/logo.jpg"
              className="artistImageLogo"
            ></img>
          </div>
        </div>
        <div className="artistInfo">
          <div className="neo-box d-flex v-center h-center flex-column">
            <div className="d-flex w-100" style={{color: 'white'}}>
              <span id="artistText">
                Hello, Visitor! 🤍
                <br /> <br /> Allow me to introduce myself. I'm an artist who
                specializes in creating intricate, monochrome artworks that
                visually represent a deeper spiritual narrative. <br />
                <br /> You might be wondering - why monochrome? Well, my
                creations are an extension of my beliefs and my very existence.
                I'm a firm believer of duality and of the fact that the
                'reality' we experience is subjected to perspective - what might
                be dark to one, could be light to others - therefore, grey.{" "}
                <br />
                <br />
                I'd love for you to join me in my journey to achieve all that
                (and more)!
              </span>
            </div>
            <div className="d-flex w-100">
              <div
                style={{
                  width: "33%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  className="media-buttons"
                  onClick={() => redirectToLink("I")}
                >
                  <img src="images/instagram.png" width={30}></img>
                </div>
              </div>
              <div
                style={{
                  width: "33%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  className="media-buttons"
                  onClick={() => redirectToLink("X")}
                >
                  <img src="images/x.png" width={30}></img>
                </div>
              </div>
              <div
                style={{
                  width: "33%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div
                  className="media-buttons"
                  onClick={() => redirectToLink("F")}
                >
                  <img src="images/foundation.png" width={40}></img>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div id="footerDisclaimer">
        <span className="padding-10">
          Artworks and Gallery by SpiritualScientist with 🤍
        </span>
      </div>
    </div>
  );
}

export default App;
